import jsPDF from 'jspdf';
// eslint-disable-next-line import/no-extraneous-dependencies
import html2canvas from 'html2canvas';
import { enqueueSnackbar } from 'notistack';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import { useForm, FormProvider } from 'react-hook-form';
import { React, useRef, useMemo, useState, useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Grid,
  Stack,
  Table,
  Button,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  TableHead,
  CardMedia,
  Typography,
  CircularProgress,
} from '@mui/material';

import { paths } from 'src/routes/routes/paths';
import useResource from 'src/routes/hooks/useCloudinaryImage';

import { useBoolean } from 'src/hooks/use-boolean';

import { fDate, fDateTime } from 'src/utils/format-time';

import NoImg from 'src/assets/other/ImgNull.jpg';
import { table } from 'src/theme/overrides/components/table';
import { clearProductDetailsState } from 'src/server/store/product';
import { getOrderDetails, updateOrderStatus } from 'src/server/api/order';

import Iconify from 'src/components/iconify';
import { RHFSelect } from 'src/components/hook-form';
import TextMaxLine from 'src/components/text-max-line';
import { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';

import SingleViewPDFDownload from './pdfDownload';

function SingleViewDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const confirm = useBoolean();
  const popover = usePopover();
  const orderId = location?.state?.uid;
  const ref = useRef(null);

  const orderDetails = useSelector((state) => state.order.orderDetails);

  const { user, shipping_address } = orderDetails;
  useEffect(() => {
    dispatch(getOrderDetails({ orderId, dispatch }));
  }, [dispatch, orderId]);

  const handleViewClick = (uid) => {
    dispatch(clearProductDetailsState());
    navigate(paths.dashboard.product_update(uid));
  };

  const [loading, setLoading] = useState(false);

  // pdf downloader fuc
  const handleDownloadPdf = async () => {
    setLoading(true);
    const element = ref?.current;
    if (!element) {
      console.error('Element not found');
      return;
    }

    const fixedWidth = 1155;
    const scaleValue = 2;

    element.style.width = `${fixedWidth}px`;
    const canvas = await html2canvas(element, {
      scale: scaleValue,
      useCORS: true,
    });
    const data = canvas.toDataURL('image/png');

    // eslint-disable-next-line new-cap
    const pdf = new jsPDF('p', 'mm', 'a4');
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    const pageHeight = pdf.internal.pageSize.getHeight();
    let heightLeft = pdfHeight;
    let position = 0;

    pdf.addImage(data, 'PNG', 0, position, pdfWidth, pdfHeight);
    heightLeft -= pageHeight;

    while (heightLeft > 0) {
      position = heightLeft - pdfHeight;
      pdf.addPage();
      pdf.addImage(data, 'PNG', 0, position, pdfWidth, pdfHeight);
      heightLeft -= pageHeight;
    }

    pdf.save(`${orderDetails?.order_id}.pdf`);
    setLoading(false);
  };

  const handleCopy = (type) => {
    enqueueSnackbar(`${type} copied!`, { variant: 'success' });
  };

  const statusOptions = [
    // { label: 'Pending', value: 'PENDING' },
    { label: 'Processing', value: 'PROCESSING' },
    // { label: 'Pickup Scheduled', value: 'PICKUP_SCHEDULED' },
    { label: 'Shipped', value: 'SHIPPED' },
    { label: 'Delivered', value: 'DELIVERED' },
    // { label: 'Cancel Initiated', value: 'CANCEL_INITIATED' },
    { label: 'Cancelled', value: 'CANCELLED' },
    { label: 'Returned', value: 'RETURNED' },
    // { label: 'Return Initiated', value: 'RETURN_INITIATED' },
  ];

  const defaultValues = useMemo(() => {
    const getStatus = () => {
      if (orderDetails?.order_status === 'RETURN_INITIATED') {
        return 'RETURNED';
      }
      if (orderDetails?.order_status === 'CANCEL_INITIATED') {
        return 'CANCELLED';
      }
      return orderDetails?.order_status || '';
    };

    return {
      order_status: getStatus(),
    };
  }, [orderDetails]);

  const methods = useForm({
    defaultValues,
  });

  const { watch, setValue } = methods;

  const handleChangeOrderStatus = () => {
    confirm.onTrue();
    popover.onClose();
  };

  const watchStatus = watch('order_status');

  const handleClose = () => {
    confirm.onFalse();
  };
  const handleDeleteProductConfirmClick = () => {
    const updateData = {
      order: orderId,
      order_status: watchStatus,
    };
    const credentials = {
      state: updateData,
      dispatch,
      handleClose,
    };
    dispatch(updateOrderStatus(credentials));
  };

  useEffect(() => {
    if (orderDetails?.uid) {
      methods.reset(defaultValues);
    }
  }, [orderDetails, methods, defaultValues]);

  const onCloseModal = () => {
    confirm.onFalse();
    const handleStatusValues = () => {
      if (orderDetails?.order_status === 'RETURN_INITIATED') {
        return 'RETURNED';
      }
      if (orderDetails?.order_status === 'CANCEL_INITIATED') {
        return 'CANCELLED';
      }
      return orderDetails?.order_status;
    };

    const updatedStatus = handleStatusValues();
    console.log(updatedStatus);
    setValue('order_status', updatedStatus);
  };

  return (
    <Stack>
      <Stack
        position="absolute"
        sx={{
          opacity: 0,
        }}
      >
        <SingleViewPDFDownload data={orderDetails} componentRef={ref} />
      </Stack>
      <Typography variant="h4" pl={2} pb={2}>
        Order Details
      </Typography>
      <Card
        sx={{
          p: 3,
        }}
      >
        <Card sx={{ py: 2, px: 3 }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h6">User</Typography>
            <Stack>
              <LoadingButton
                size="small"
                variant="outlined"
                color="success"
                loading={loading}
                startIcon={<Iconify icon="simple-line-icons:cloud-download" width="16px" />}
                onClick={handleDownloadPdf}
              >
                Download Order
              </LoadingButton>
            </Stack>
          </Stack>

          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {user?.name}
          </Typography>
          <CopyToClipboard text={user?.phone} onCopy={() => handleCopy('Phone Number')}>
            <Typography
              variant="body2"
              sx={{
                cursor: 'pointer',
              }}
            >
              Contact No : +{user?.country_code} {user?.phone}{' '}
              <Iconify icon="proicons:copy" width="16px" />
            </Typography>
          </CopyToClipboard>
          <Typography variant="body2">Joined on : {fDate(user?.createdAt)}</Typography>
          <Grid container spacing={3} pt={3}>
            <Grid item md={5} lg={4}>
              <Box>
                <Typography variant="h6">Order</Typography>
                <Typography variant="body2" py={0.5}>
                  Order id :&nbsp;&nbsp; {orderDetails?.order_id}
                </Typography>
                <Stack direction="row" alignItems="center" justifyContent="start">
                  <Typography variant="body2">Order Status : &nbsp;&nbsp;</Typography>
                  <FormProvider {...methods}>
                    <Stack width="50%">
                      <RHFSelect name="order_status" size="small">
                        {statusOptions?.map((option) => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                            onClick={handleChangeOrderStatus}
                          >
                            {option?.label}
                          </MenuItem>
                        ))}
                      </RHFSelect>
                    </Stack>
                  </FormProvider>
                </Stack>
                <Typography variant="body2" py={0.5}>
                  {' '}
                  Order Date :&nbsp;&nbsp; {fDateTime(orderDetails?.order_initiated_at)}
                </Typography>
                <Typography variant="body2">
                  Invoice Date :&nbsp;&nbsp; {fDate(orderDetails?.order_initiated_at)}
                </Typography>
                {orderDetails?.order_status === 'PROCESSING' ||
                orderDetails?.order_status === 'SHIPPED' ? (
                  <Typography variant="body2">
                    Estimate Delivery Date :&nbsp;&nbsp;{' '}
                    {fDateTime(orderDetails?.estimated_delivery_date)}
                  </Typography>
                ) : (
                  ''
                )}
                {orderDetails?.order_status === 'CANCELLED' ? (
                  <Typography variant="body2">
                    Cancelled Date :&nbsp;&nbsp; {fDate(orderDetails?.cancelled_at)}
                  </Typography>
                ) : (
                  ''
                )}
                {orderDetails?.order_status === 'RETURNED' ? (
                  <Typography variant="body2">
                    Returned Date :&nbsp;&nbsp; {fDate(orderDetails?.returned_at)}
                  </Typography>
                ) : (
                  ''
                )}
                {orderDetails?.order_status === 'DELIVERED' ? (
                  <Typography variant="body2">
                    Delivery Date :&nbsp;&nbsp; {fDate(orderDetails?.delivered_at)}
                  </Typography>
                ) : (
                  ''
                )}
              </Box>
            </Grid>
            <Grid item md={4} lg={4}>
              <Box>
                <Typography variant="h6">Payment</Typography>
                {orderDetails?.createdAt && (
                  <Typography variant="body2" py={0.5}>
                    Invoice Date: {fDate(orderDetails?.createdAt)}
                  </Typography>
                )}
                {orderDetails?.payment_type && (
                  <Typography variant="body2">
                    {' '}
                    Payment Type: {orderDetails?.payment_type}
                  </Typography>
                )}
                {orderDetails?.transaction_id && (
                  <CopyToClipboard
                    text={orderDetails?.transaction_id}
                    onCopy={() => handleCopy('Transaction ID')}
                  >
                    <Typography
                      variant="body2"
                      py={0.5}
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      Transaction Id: {orderDetails?.transaction_id}{' '}
                      <Iconify icon="proicons:copy" width="16px" />
                    </Typography>
                  </CopyToClipboard>
                )}
              </Box>
            </Grid>
            {shipping_address?.tag ? (
              <Grid item md={4} lg={4}>
                <Box>
                  <Typography variant="h6">Shipping</Typography>
                  <Typography variant="body2" pt={0.5} sx={{ fontWeight: 'bold' }}>
                    {shipping_address?.name}
                  </Typography>
                  <Typography variant="body2">
                    {shipping_address?.address}
                    {shipping_address?.address_line_1}
                  </Typography>
                  <Typography variant="body2">
                    {shipping_address?.district?.title?.english}
                  </Typography>
                  <Typography variant="body2">
                    {shipping_address?.state?.title?.english},
                    {shipping_address?.country?.title?.english}
                  </Typography>
                  <CopyToClipboard
                    text={shipping_address?.phone}
                    onCopy={() => handleCopy('Phone Number')}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      Contact no: +{shipping_address?.country_code} {shipping_address?.phone}{' '}
                      <Iconify width="16px" icon="proicons:copy" />{' '}
                    </Typography>
                  </CopyToClipboard>
                </Box>
              </Grid>
            ) : (
              <Grid item md={4} lg={4}>
                <Box>
                  <Typography variant="h6">Self Pickup</Typography>
                  <Typography variant="body2" pt={0.5} sx={{ fontWeight: 'bold' }}>
                    {orderDetails?.self_pickup?.name}
                  </Typography>
                  <Typography variant="body2">
                    {orderDetails?.self_pickup?.address}
                    {orderDetails?.self_pickup?.address_line_1}
                  </Typography>
                  <Typography variant="body2">
                    {orderDetails?.self_pickup?.district?.title?.english}
                  </Typography>
                  <Typography variant="body2">
                    {orderDetails?.self_pickup?.state?.title?.english},
                    {orderDetails?.self_pickup?.country?.title?.english}
                  </Typography>
                  <Typography variant="body2">
                    Contact no: +{orderDetails?.self_pickup?.country_code}{' '}
                    {orderDetails?.self_pickup?.phone} <Iconify width="16px" icon="proicons:copy" />{' '}
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </Card>
        <ConfirmDialog
          open={confirm.value}
          onClose={onCloseModal}
          title="Order Status"
          content="Are you sure want to change order status ?"
          action={
            <Button variant="contained" color="error" onClick={handleDeleteProductConfirmClick}>
              Update {loading && <CircularProgress size={14} sx={{ mx: 1 }} />}
            </Button>
          }
        />

        <Grid container spacing={2} mt={2}>
          <Grid item md={9} lg={9}>
            <Card sx={{}}>
              <Table size={table.dense ? 'small' : 'medium'}>
                <TableHead>
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell>Qty</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Total</TableCell>
                    {/* <TableCell>Status</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderDetails?.order_items?.map((item, key) => (
                    <TableRow key={key}>
                      <TableCell>
                        <Stack direction="row" alignItems="center" justifyContent="start">
                          <CardMedia
                            component="img"
                            src={
                              item?.product?.images
                                ? // eslint-disable-next-line react-hooks/rules-of-hooks
                                  useResource(item?.product?.images[0]?.url, 'w_500')
                                : NoImg
                            }
                            height="50px"
                            style={{
                              objectFit: 'contain',
                              width: 50,
                            }}
                          />
                          <Stack>
                            <Button onClick={() => handleViewClick(item?.product?.uid)}>
                              <TextMaxLine line={2} sx={{ maxWidth: 350, textAlign: 'start' }}>
                                <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
                                  {item?.product?.name?.english}
                                </Typography>
                              </TextMaxLine>
                            </Button>
                            <Typography color="#919EAB" variant="caption" pl={1}>
                              {item?.product?.sap_id}
                            </Typography>
                          </Stack>
                        </Stack>
                      </TableCell>
                      <TableCell>{item?.quantity}</TableCell>
                      <TableCell>{item?.product_price}</TableCell>
                      <TableCell>{item?.product_item_price}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Card>
          </Grid>
          <Grid item md={3} lg={3}>
            <Card sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Summary
              </Typography>
              <Stack direction="row" justifyContent="space-between">
                {/* Left Column */}
                <Stack spacing={1}>
                  <Typography variant="body2" sx={{ py: 0.5 }}>
                    Item Total
                  </Typography>
                  <Typography variant="body2" sx={{ py: 0 }}>
                    Shipping
                  </Typography>
                  {orderDetails?.payment_type === 'COD' && (
                    <Typography variant="body2" sx={{ py: 0 }}>
                      COD Charge
                    </Typography>
                  )}
                  {/* <Typography variant="body2" sx={{ py: 0, color: 'rgba(34, 197, 94, 1)' }}>
                    Discount
                  </Typography> */}
                  <Typography variant="body2" sx={{ py: 0, color: 'rgba(34, 197, 94, 1)' }}>
                    Coupon Discount
                  </Typography>
                  {/* <Typography variant="body2" sx={{ py: 0 }}>
                    Tax
                  </Typography> */}

                  <Typography variant="subtitle2" sx={{ py: 0.5 }}>
                    Gross Total
                  </Typography>
                </Stack>

                {/* Right Column */}
                <Stack spacing={1}>
                  <Typography variant="body2" sx={{ py: 0.5 }}>
                    OMR {orderDetails?.items_price ? orderDetails?.items_price.toFixed(2) : '0'}
                  </Typography>
                  <Typography variant="body2" sx={{ py: 0 }}>
                    OMR{' '}
                    {orderDetails?.total_shipping_price
                      ? orderDetails?.total_shipping_price.toFixed(2)
                      : '0'}
                  </Typography>
                  <Typography variant="body2" sx={{ py: 0 }}>
                    OMR {orderDetails?.cod_charge}
                  </Typography>
                  {/* <Typography variant="body2" sx={{ py: 0, color: 'rgba(34, 197, 94, 1)' }}>
                    OMR{' '}
                    {orderDetails?.total_discount
                      ? orderDetails.total_discount.toFixed(2)
                      : '0.000'}
                  </Typography> */}
                  <Typography variant="body2" sx={{ py: 0, color: 'rgba(34, 197, 94, 1)' }}>
                    OMR{' '}
                    {orderDetails?.total_coupon_discount
                      ? orderDetails.total_coupon_discount.toFixed(3)
                      : '0'}
                  </Typography>
                  {/* <Typography variant="body2" sx={{ py: 0 }}>
                    OMR {orderDetails?.tax_value}
                  </Typography> */}

                  <Typography variant="subtitle2" sx={{ py: 0.5 }}>
                    OMR {orderDetails?.total_price ? orderDetails?.total_price.toFixed(2) : '0'}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Card>
    </Stack>
  );
}

export default SingleViewDetails;
